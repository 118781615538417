export interface IFilterList {
  filter: IFilterClauseList;
}

export interface IFilterClauseList {
  where?: IWhereClauseList;
  skip?: Number;
  limit?: Number;
  order?: String;
  include?: String[];
  status?: String;
}

export interface IWhereClauseList {
  and?: Array<IWhereClauseList>;
  or?: Array<IWhereClauseList>;
  [key: string]: Object;
}

export class FilterList implements IFilterList {
  filter: IFilterClauseList = {
    where: { and: [{ active: true }] }
  };

  constructor(skip: Number = 0, limit: Number = 10, order: String = `updatedAt DESC`) {
    this.filter.skip = skip;
    this.filter.limit = limit;
    this.filter.order = order;
  }
}
export class FilterListApprovalFlow implements IFilterList {
  filter: IFilterClauseList = {
    where: {}
  };

  constructor(skip: Number = 0, limit: Number = 10, order: String = `updatedAt DESC`) {
    this.filter.skip = skip;
    this.filter.limit = limit;
    this.filter.order = order;
  }
}
export class FilterListDefault implements IFilterList {
  filter: IFilterClauseList = {
    where: { and: [] }
  };

  constructor(skip: Number = 0, limit: Number = 10, order: String = `updatedAt DESC`) {
    this.filter.skip = skip;
    this.filter.limit = limit;
    this.filter.order = order;
  }
}
